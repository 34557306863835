<template>
  <moe-page title="物流公司管理">
    <!-- 商品信息 -->
    <moe-table ref="expressTable" url="/shop/express/queryShopExpressList" :params="expressParams" :number-show="false" row-key="id">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline"
          @click="$moe_coordinator.navigateTo('/menu/expressAdd')">添加物流公司</el-button>
      </template>
      <el-table-column prop="id" label="ID" min-width="130" />
      <el-table-column prop="coverUrl" label="物流公司图片" width="130">
        <template slot-scope="{ row }">
          <moe-image :src="row.coverUrl" width="80px" height="80px" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="物流公司名称" min-width="130" />
      <el-table-column prop="com" label="物流公司编码" min-width="130" />
      <el-table-column prop="wxCom" label="微信快递公司编码	" min-width="150" />
      <el-table-column label="操作" width="400" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/menu/expressAdd`, { id: row.id })">编辑</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteExpress(row.id)">删除</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'ExpressList',
  data() {
    //日志筛选信息
    let expressParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
    };

    return {
      expressParams, //日志筛选信息
    };
  },
  methods: {
    /**
     * 删除物流公司
     **/
    deleteExpress(expressId) {
      this.$moe_layer.confirm('是否要删除该物流公司 ?', () => {
        // console.log(id)
        // 调用删除账号API
        this.$moe_api.GENERAL_API.deleteShopExpress({ expressId }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            //刷新广告表格
            this.$refs.expressTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },

  },
};
</script>